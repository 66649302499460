<template>
  <!-- 列表样式 -->
  <div class="chat-robot-box">
    <div class="message-header"
        :class="{'border-bottom': !isChild}">
      <em class="icon-dot"></em>近期热点咨询
    </div>
    <ul class="list">
      <li
        v-for="(item) in list"
        :key="item.id"
        @click="selectItem(item)"
      >
        {{item.desc}}
      </li>
    </ul>
    <!-- 查看更多按钮 -->
    <div
      class="view-more"
      :class="{ open: allDisplayed }"
      v-if="hasMore"
      @click="allDisplayed = !allDisplayed"
    >{{allDisplayed ? '收起' : '更多'}}</div>
  </div>
</template>

<script>
export default {
  name: 'BubbleList',
  props: {
    message: Object,
    isChild: Boolean
  },
  data () {
    return {
      defaultCount: 6,
      allDisplayed: false
    }
  },
  computed: {
    hasMore () {
      return this.message.content.length > this.defaultCount
    },
    list () {
      if (this.allDisplayed) {
        return this.message.content
      } else {
        return this.message.content.slice(0, this.defaultCount)
      }
    }
  },
  methods: {
    selectItem (item) {
      this.$store.dispatch('askQuestion', {
        from: 2,
        question: item.desc,
        dataType: 'hot-selected',
        onlyRobot: true
      })
    }
  }
}
</script>
